
import { ApexOptions } from "apexcharts"
import { Vue, Component, Prop } from "vue-property-decorator"
import VueApexCharts from "vue-apexcharts"
import { CONSULTANT_ROLES } from "@/constants/data"

@Component({
  components: {
    VueApexCharts,
  },
})
export default class UtilizationGraph extends Vue {
  @Prop() readonly graphData!: { role: string; percent: number }[]

  get chartSeries() {
    return [{ name: "Utilization", data: this.graphData.map((stat) => stat.percent) }]
  }

  get categories() {
    return this.graphData.map((data: any) => data.role)
  }

  chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: 250,
      fontFamily: "Inter",
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 1,
        color: "#000",
        opacity: 0.15,
      },
    },
    colors: ["#C3B0DE", "#9FDDD2", "#F6DF8E", "#F5CDD8", "#FFE5E5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetY: -10,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "90%",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
      formatter: function (val: any): any {
        if (val > 0) {
          return val + "%"
        }
      },
    },
    xaxis: {
      type: "category",
      categories: this.categories,
      crosshairs: {
        show: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%"
        },
      },
    },
    fill: {
      opacity: 1,
      pattern: {
        width: 10,
      },
    },
    grid: {
      padding: {
        right: 0,
        left: 0,
      },
      show: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      position: "top",
      offsetX: 500,
      offsetY: 5,
      fontSize: "14px",
      fontFamily: "Inter",
      markers: {
        width: 32,
        height: 20,
        offsetX: -5,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    noData: {
      text: "No data available",
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        show: true,
        formatter: (val: any) => {
          return CONSULTANT_ROLES[val] || val
        },
      },
    },
  }

  series = this.chartSeries
}
