
import { RatedConsultantType } from "@/views/talent/Dashboard.vue"
import { Vue, Component, Prop } from "vue-property-decorator"

@Component
export default class ConsultantRatingCard extends Vue {
  @Prop() readonly consultantData!: RatedConsultantType
  @Prop() readonly lastItem!: number
  @Prop() readonly isLoading!: boolean

  getInitials(name: string) {
    const names = name.split(" ")
    const initials = names.map((n) => n.charAt(0)).join("")
    return initials.toUpperCase()
  }
}
