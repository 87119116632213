
import { talentRoleGenderStat } from "@/views/talent/Dashboard.vue"
import { ApexOptions } from "apexcharts"
import { Vue, Component, Prop } from "vue-property-decorator"
import VueApexCharts from "vue-apexcharts"
import { CONSULTANT_ROLES } from "@/constants/data"

@Component({
  components: {
    VueApexCharts,
  },
})
export default class ApplicationsTracker extends Vue {
  @Prop() readonly graphData!: { [key: string]: talentRoleGenderStat[] }

  get chartSeries() {
    let data: { [key: string]: number[] } = {
      approved: [],
      rejected: [],
      archived: [],
      applying: [],
      shortlisted: [],
    }

    Object.values(this.graphData).forEach((stat: any) => {
      stat.forEach((stat: any) => {
        data[stat.type].push(stat.count)
      })
    })

    return [
      { name: "Approved", data: data.approved },
      { name: "Rejected", data: data.rejected },
      { name: "Archived", data: data.archived },
      { name: "Applying", data: data.applying },
      { name: "Shortlisted", data: data.shortlisted },
    ]
  }

  get categories() {
    return Object.keys(this.graphData)
  }

  chartOptions: ApexOptions = {
    chart: {
      type: "heatmap",
      fontFamily: "Inter",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
    },
    colors: ["#C3B0DE", "#9FDDD2", "#F6DF8E", "#F5CDD8", "#FFE5E5"],

    xaxis: {
      type: "category",
      categories: this.categories,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 50,
        distributed: true,
        colorScale: {
          inverse: true,
          // min: 0,
          // max: 100,
        },

        radius: 5,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: false,

      x: {
        show: true,
        formatter: function (val: any) {
          return CONSULTANT_ROLES[val] || val
        },
      },
      y: {
        title: {
          formatter: (seriesName) => seriesName,
        },
      },

      marker: {
        show: false,
      },
    },
  }

  series = this.chartSeries
}
