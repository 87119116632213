
import { Vue, Component } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import ApplicationsTracker from "@/components/talent/TrackerCard.vue"
import ConsultantRatingCard from "@/components/talent/ConsultantRatingCard.vue"
import RoleGenderGraph from "@/components/talent/graphs/RoleGenderGraph.vue"
import RoleLocationGraph from "@/components/talent/graphs/RoleLocationGraph.vue"
import RoleAvailabilityGraph from "@/components/talent/graphs/RoleAvailabilityGraph.vue"
import UtilizationGraph from "@/components/talent/graphs/UtilizationGraph.vue"

import {
  TalentApplicationsDistribution,
  TalentApplicationsDistributionQuery,
  TalentDistribution,
  TalentDistributionQuery,
  TalentRatingEntry,
  TalentRatings,
  TalentRatingsQuery,
  TalentUtilization,
  TalentUtilizationQuery,
} from "@/gql"

export type talentRoleGenderStat = {
  gender: string
  count: number
}

export type talentRoleLocationStat = {
  role: string
  count: number
  location: string
}

export type RatedConsultantType = {
  role: string
  consultants: {
    id: string
    name: string
    avatar: string
    role: string
  }[]
}

@Component({
  components: {
    AppPage,
    ApplicationsTracker,
    ConsultantRatingCard,
    RoleGenderGraph,
    RoleLocationGraph,
    RoleAvailabilityGraph,
    UtilizationGraph,
  },
})
export default class Dashboard extends Vue {
  topPanel = [0]
  bottomPanel = [0]
  isLoading = true
  ratingsLoading = false
  isRoleLocationGraphLoading = true
  isRoleAvailLoading = true
  isApplicationDataLoading = true
  isTalentUtilizationLoading = true
  loadingError = false

  tab = null

  ratings: TalentRatingEntry[] | null = null
  roleGenderStats: Record<string, { count: number; gender: string }[]> | null = null
  roleLocationStats: Record<string, { count: number; location: string; role: string }[]> | null =
    null
  roleAvailabilityStats: Record<string, any[]> | null = null
  applicationsDistribution: Record<string, any[]> | null = null

  topRatedConsultants: RatedConsultantType[] | [] = []
  lowestRatedConsultants: RatedConsultantType[] | [] = []
  talentUtilization: { role: string; percent: number }[] = []

  async created() {
    this.fetchData()
  }

  fetchData() {
    this.fetchRoleGenderDistribution()
    this.fetchTalentRatings()
    this.fetchRoleAvailabilityDistribution()
    this.fetchApplicationsDistribution()
    this.fetchRoleUtilization()
  }

  getInitials(name: string) {
    const names = name.split(" ")
    const initials = names.map((n) => n.charAt(0)).join("")
    return initials.toUpperCase()
  }

  async fetchTalentRatings() {
    this.ratingsLoading = true
    const result = await this.$apollo.query({
      query: TalentRatingsQuery,
      variables: {
        filter: { by: "role" },
      },
    })

    if (result.data && result.data.talentDashboardData.talentRatings) {
      this.ratingsLoading = false
      this.loadingError = false

      this.topRatedConsultants = this.transformTalentRatings(
        result.data.talentDashboardData.talentRatings,
        "top"
      ) as RatedConsultantType[]
      this.lowestRatedConsultants = this.transformTalentRatings(
        result.data.talentDashboardData.talentRatings,
        "bottom"
      ) as RatedConsultantType[]
    } else {
      this.ratingsLoading = false
      this.loadingError = true
    }
  }

  async fetchRoleGenderDistribution() {
    const result = await this.$apollo.query({
      query: TalentDistributionQuery,
      variables: {
        filter: { by: ["role", "gender"] },
      },
      errorPolicy: "all",
    })

    if (result.data && result.data.talentDashboardData.talentDistribution) {
      this.isLoading = false
      this.roleGenderStats = this.transformRoleGenderDistribution(
        result.data.talentDashboardData.talentDistribution
      )
    } else {
      this.loadingError = true
      this.isLoading = false
    }
  }

  async fetchRoleLocationDistribution() {
    const result = await this.$apollo.query({
      query: TalentDistributionQuery,
      variables: {
        filter: { by: ["region", "role"] },
      },
      errorPolicy: "all",
    })

    if (result.data && result.data.talentDashboardData.talentDistribution) {
      this.isRoleLocationGraphLoading = false
      this.roleLocationStats = this.transformRoleLocationDistribution(
        result.data.talentDashboardData.talentDistribution
      )
    } else {
      this.loadingError = true
      this.isRoleLocationGraphLoading = false
    }
  }

  async fetchRoleAvailabilityDistribution() {
    const result = await this.$apollo.query({
      query: TalentDistributionQuery,
      variables: {
        filter: { by: ["availability", "role"] },
      },
      errorPolicy: "all",
    })

    if (result.data && result.data.talentDashboardData.talentDistribution) {
      this.isRoleAvailLoading = false
      this.roleAvailabilityStats = this.transformRoleAvailabilityDistribution(
        result.data.talentDashboardData.talentDistribution
      )
    } else {
      this.loadingError = true
      this.isRoleAvailLoading = false
    }
  }

  async fetchApplicationsDistribution() {
    const result = await this.$apollo.query({
      query: TalentApplicationsDistributionQuery,
      variables: {
        filter: { by: "role" },
      },
      errorPolicy: "all",
    })

    if (result.errors) {
      this.isApplicationDataLoading = false
      this.loadingError = true
      return
    }

    if (result.data && result.data.talentDashboardData.talentApplicationsDistribution) {
      this.isApplicationDataLoading = false
      this.applicationsDistribution = this.transformApplicationsDistribution(
        result.data.talentDashboardData.talentApplicationsDistribution
      )
    } else {
      this.isApplicationDataLoading = false
    }
  }

  async fetchRoleUtilization() {
    const result = await this.$apollo.query({
      query: TalentUtilizationQuery,
      variables: {
        filter: { by: "role" },
      },
      errorPolicy: "all",
    })

    if (result.data && result.data.talentDashboardData.talentUtilization) {
      this.isTalentUtilizationLoading = false
      this.talentUtilization = this.transformUtilization(
        result.data.talentDashboardData.talentUtilization
      )
    } else {
      this.loadingError = true
      this.isTalentUtilizationLoading = false
    }
  }

  transformTalentRatings(rating: TalentRatings, level = "") {
    switch (level) {
      case "top":
        return rating.values.map((value) => {
          return {
            role: value.key,
            consultants: value.top.map((consultant) => {
              return {
                id: consultant.id,
                name: consultant.name,
                role: consultant.jobTitle?.name,
                rating: consultant.rating?.toFixed(1),
                avatar: consultant.user?.avatar?.url,
              }
            }),
          }
        })
      case "bottom":
        return rating.values.map((value) => {
          return {
            role: value.key,
            consultants: value.bottom.map((consultant) => {
              return {
                id: consultant.id,
                name: consultant.name,
                role: consultant.jobTitle?.name,
                avatar: consultant.user?.avatar?.url,
                rating: consultant.rating?.toFixed(1),
              }
            }),
          }
        })

      default:
        break
    }
  }

  transformRoleGenderDistribution(distribution: TalentDistribution) {
    const init: Record<string, { count: number; gender: string }[]> = {}

    const result = distribution.values.reduce((grouped, value) => {
      grouped[value.keys[0]] = grouped[value.keys[0]] || [
        {
          count: 0,
          gender: "Male",
        },
        {
          count: 0,
          gender: "Female",
        },
        {
          count: 0,
          gender: "-",
        },
      ]

      grouped[value.keys[0]]?.map((el: any, index: number) => {
        if (el?.gender === value?.keys[1]) {
          let old = grouped[value.keys[0]][index]
          let newObject = { gender: old?.gender, count: old?.count + value?.count }
          grouped[value.keys[0]][index] = newObject
        }
      })

      return grouped
    }, init)

    return result
  }

  transformRoleAvailabilityDistribution(distribution: TalentDistribution) {
    const init: Record<string, any> = {}

    const result = distribution.values.reduce((grouped, value) => {
      grouped[value.keys[1]] = grouped[value.keys[1]] || [
        {
          count: 0,
          availability: "available_full_time",
          role: value.keys[1],
        },
        {
          count: 0,
          availability: "available_part_time",
          role: value.keys[1],
        },
      ]

      grouped[value.keys[1]]?.map((el: any, index: number) => {
        if (el?.availability === value?.keys[0]) {
          let old = grouped[value.keys[1]][index]
          let newObject = {
            availability: old?.availability,
            count: old?.count + value?.count,
          }

          grouped[value.keys[1]][index] = newObject
        }
      })

      return grouped
    }, init)

    return result
  }

  transformRoleLocationDistribution(distribution: TalentDistribution) {
    const locations = [
      "British Isles",
      "Central Europe",
      "East Africa",
      "South Africa",
      "Southern Asia",
      "West Africa",
      "West Europe",
      "North Africa",
      "Western Asia",
    ]

    const init: Record<string, any> = {}

    const result = distribution.values.reduce((grouped, value) => {
      grouped[value.keys[1]] =
        grouped[value.keys[1]] ||
        locations.map((loc) => {
          return {
            count: 0,
            location: loc,
            role: value.keys[1],
          }
        })

      grouped[value.keys[1]]?.map((el: any, index: number) => {
        if (el?.location === value?.keys[0]) {
          let old = grouped[value.keys[1]][index]
          let newObject = {
            location: old?.location,
            count: old?.count + value?.count,
            role: value.keys[1],
          }
          grouped[value.keys[1]][index] = newObject
        }
      })

      return grouped
    }, init)

    return result
  }

  transformApplicationsDistribution(distribution: TalentApplicationsDistribution) {
    const init: Record<string, any> = {}

    const applicationTypes = ["archived", "approved", "rejected", "applying", "shortlisted"]
    const result = distribution.values.reduce((grouped, value) => {
      grouped[value.keys[1]] =
        grouped[value.keys[1]] ||
        applicationTypes.map((type) => {
          return {
            count: 0,
            type,
          }
        })

      grouped[value.keys[1]]?.map((el: any, index: number) => {
        if (el?.type === value?.keys[0]) {
          let old = grouped[value.keys[1]][index]
          let newObject = { type: old?.type, count: old?.count + value?.count }
          grouped[value.keys[1]][index] = newObject
        }
      })

      return grouped
    }, init)

    return result
  }

  transformUtilization(data: TalentUtilization) {
    return data.values.map((value) => {
      return {
        role: value.by?.role || "",
        percent: value.percent,
      }
    })
  }
}
