import { render, staticRenderFns } from "./TrackerCard.vue?vue&type=template&id=54d8f049&"
import script from "./TrackerCard.vue?vue&type=script&lang=ts&"
export * from "./TrackerCard.vue?vue&type=script&lang=ts&"
import style0 from "./TrackerCard.vue?vue&type=style&index=0&id=54d8f049&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports