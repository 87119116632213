
import { talentRoleGenderStat } from "@/views/talent/Dashboard.vue"
import { ApexOptions } from "apexcharts"
import { Vue, Component, Prop } from "vue-property-decorator"
import VueApexCharts from "vue-apexcharts"
import { CONSULTANT_ROLES } from "@/constants/data"

@Component({
  components: {
    VueApexCharts,
  },
})
export default class RoleAvailabilityGraph extends Vue {
  @Prop() readonly graphData!: { [key: string]: talentRoleGenderStat[] }

  get chartSeries() {
    let fullTime: number[] = []
    let partTime: number[] = []

    Object.values(this.graphData).forEach((stat: any) => {
      stat.forEach((stat: any) => {
        if (stat.availability === "available_full_time") {
          fullTime.push(stat.count)
        }
        if (stat.availability === "available_part_time") {
          partTime.push(stat.count)
        }
      })
    })

    return [
      { name: "Full Time", data: fullTime, color: "#C3B0DE" },
      { name: "Part Time", data: partTime, color: "#9FDDD2" },
    ]
  }

  get roleAvailabilityKeys() {
    return Object.keys(this.graphData)
  }

  chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: 250,
      stacked: true,
      stackType: "100%",
      fontFamily: "Inter",
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 1,
        color: "#000",
        opacity: 0.15,
      },
    },
    colors: ["#C3B0DE", "#9FDDD2"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "top",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          legend: {
            offsetX: 100,
            position: "top",
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          legend: {
            offsetX: 300,
            position: "top",
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "90%",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
    },
    noData: {
      text: "No data available",
    },
    xaxis: {
      type: "category",

      categories: this.roleAvailabilityKeys,
    },
    yaxis: {
      labels: {
        show: false,
      },
      showForNullSeries: false,
    },
    fill: {
      opacity: 1,
      pattern: {
        width: 10,
      },
    },
    grid: {
      padding: {
        right: 0,
        left: 0,
      },
      show: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      position: "top",
      offsetX: 500,
      offsetY: 5,
      fontSize: "14px",
      fontFamily: "Inter",
      markers: {
        width: 32,
        height: 20,
        offsetX: -5,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    tooltip: {
      x: {
        show: true,
        formatter: (val: any) => {
          return CONSULTANT_ROLES[val] || val
        },
      },
    },
  }

  series = this.chartSeries
}
