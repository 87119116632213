
import { talentRoleGenderStat } from "@/views/talent/Dashboard.vue"
import { ApexOptions } from "apexcharts"
import { Vue, Component, Prop } from "vue-property-decorator"
import VueApexCharts from "vue-apexcharts"
import { CONSULTANT_ROLES } from "@/constants/data"

@Component({
  components: {
    VueApexCharts,
  },
})
export default class RoleGenderGraph extends Vue {
  @Prop() readonly graphData!: { [key: string]: talentRoleGenderStat[] }

  get roleGenderKeys() {
    return Object.keys(this.graphData)
  }

  get roleGenderChartSeries() {
    let maleData: number[] = []
    let femaleData: number[] = []
    let otherData: number[] = []

    Object.values(this.graphData).forEach((stat) => {
      stat.forEach((stat) => {
        if (stat.gender === "Male") {
          maleData.push(stat.gender === "Male" ? stat.count : 0)
        }
        if (stat.gender === "Female") {
          femaleData.push(stat.gender === "Female" ? stat.count : 0)
        }
        if (stat.gender === "-") {
          otherData.push(stat.gender === "-" ? stat.count : 0)
        }
      })
    })

    return [
      { name: "Male", data: maleData, color: "#C3B0DE" },
      { name: "Female", data: femaleData, color: "#9FDDD2" },
      { name: "Other", data: otherData, color: "#FFE5E5" },
    ]
  }

  series = this.roleGenderChartSeries

  chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: "100%",
    },
    responsive: [
      {
        breakpoint: 1100,
        options: {
          legend: {
            offsetX: 60,
            position: "top",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          legend: {
            offsetX: 60,
            position: "top",
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          legend: {
            offsetX: 200,
            position: "top",
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },

        barHeight: "90%",
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "10px",
        colors: ["#000"],
      },

      formatter: function (val: any) {
        if (val > 0) {
          return val
        }
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,

      x: {
        show: true,
        formatter: (val: any) => {
          return CONSULTANT_ROLES[val] || val
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: this.roleGenderKeys,
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        // offsetY: -20,
        align: "center",
        style: {
          colors: ["#000"],
        },
      },
    },
    noData: {
      text: "No data available",
    },
    grid: {
      show: false,
    },
    legend: {
      position: "top",
      fontSize: "14px",
      fontFamily: "Inter",
      offsetX: 300,
      offsetY: 20,
      markers: {
        width: 32,
        height: 20,
        offsetX: -5,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
  }
}
