
import { ApexOptions } from "apexcharts"
import { Vue, Component, Prop } from "vue-property-decorator"
import VueApexCharts from "vue-apexcharts"
import { CONSULTANT_ROLES } from "@/constants/data"

@Component({
  components: {
    VueApexCharts,
  },
})
export default class RoleLocationGraph extends Vue {
  @Prop() readonly graphData!: any

  get roleLocationChartSeries() {
    const results = Object.values(this.graphData).map((data: any) => {
      return {
        name: data[0].role,
        data: data.map((el: any) => {
          return el.count
        }),
      }
    })

    return results
  }

  get categories() {
    let singleData: any = Object.values(this.graphData)[0]
    return singleData.map((data: any) => {
      return data.location
    })
  }

  chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: 250,
      stacked: true,
      stackType: "100%",
      fontFamily: "Inter",
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 1,
        color: "#000",
        opacity: 0.15,
      },
    },
    colors: ["#C3B0DE", "#9FDDD2", "#F6DF8E", "#F5CDD8", "#FFE5E5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetY: -10,
          },
        },
      },
      {
        breakpoint: 1100,
        options: {
          legend: {
            markers: {
              width: 15,
              height: 10,
            },
            itemMargin: {
              horizontal: 5,
              vertical: 0,
            },
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          legend: {
            markers: {
              width: 20,
              height: 15,
            },
            itemMargin: {
              horizontal: 5,
              vertical: 0,
            },
          },
        },
      },
      {
        breakpoint: 1300,
        options: {
          legend: {
            markers: {
              width: 20,
              height: 15,
            },
            itemMargin: {
              horizontal: 5,
              vertical: 0,
            },
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          legend: {
            markers: {
              width: 20,
              height: 15,
            },
            itemMargin: {
              horizontal: 10,
              vertical: 0,
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "90%",
      },
    },
    noData: {
      text: "No data available",
    },
    xaxis: {
      categories: this.categories,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
      pattern: {
        width: 10,
      },
    },
    grid: {
      padding: {
        right: 0,
        left: 0,
        top: 20,
      },
      show: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 20,
      fontSize: "10px",
      fontFamily: "Inter",
      markers: {
        width: 20,
        height: 15,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    dataLabels: {
      style: {
        fontSize: "14px",
        fontFamily: "Inter",
        colors: ["#000"],
      },
    },
    tooltip: {
      x: {
        formatter: (val: any) => {
          return val
        },
      },
      y: {
        title: {
          formatter: (seriesName: any) => {
            return CONSULTANT_ROLES[seriesName] || seriesName
          },
        },
        formatter: (val: any) => {
          return val
        },
      },
    },
  }

  series = this.roleLocationChartSeries
}
